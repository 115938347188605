/* ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■ ↓↓↓↓↓  Global Styles  ↓↓↓↓↓ ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■ */

body {
  background: #0d0d13;
  position: relative;
  overflow-x: hidden;
}

.App {
  background: #0d0d13;
}

.footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  color: white;
  text-align: center;
}

.doc-container {
  max-width: 900px;
}

/* @media (max-width: 600px) {
  .dashboard-manager .disappear-on-small-screens {
    display: none !important;
  }
} */

@media (max-width: 768px) {
  .disappear-on-small-screens {
    display: none !important;
  }
}

.code-snippet pre {
  .code-snippet {
    max-width: 700px;
    overflow-x: auto; /* Allows horizontal scrolling if the content exceeds the max width */
    white-space: pre-wrap; /* This makes sure the code wraps within the container */
    word-wrap: break-word; /* This helps to wrap long lines */
    background-color: #f5f5f5; /* Background color for better readability */
    padding: 1rem; /* Padding for some spacing */
    border-radius: 5px; /* Optional: Rounded corners */
    margin: 1rem 0; /* Optional: Margin for spacing between snippets */
  }
  
}

.code-snippet code {
  font-family: 'Fira Code', monospace;
  font-size: 0.8em; /* Adjust this value to make the text smaller */
}


.hero-container {
  display: flex;
  /* height: 70vh; */
  flex-direction: row;
  align-items: stretch; /* Ensures children stretch to fill the container */
  width: 100%;
  /* min-height: 75vh; */
}

.hero-image {
  width: 90%;
}

.assets-image {
  width: 50%;
}

.dashboard-image {
  width: 100%;
}

.hero-image-2 {
  width: 75%;
}

.hero-left {
  flex: 1; /* Take up equal space with the right side */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: left;
  padding: 10px;
}

.hero-right {
  flex: 1; /* Take up equal space with the left side */
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  
}

@media (max-width: 1000px) {
  .hero-container {
    flex-direction: column; /* Stack the elements in a column layout */
    height: auto; /* Adjust height to accommodate both elements */
  }

  .hero-left, .hero-right {
    flex: none; /* Remove flex property to allow elements to take full width */
    width: 100%; /* Ensure elements take full width of the container */
    padding: 10px; /* Add padding for spacing */
  }
}

/* App.css */
.profile-container {
  margin-top: 50px;
}

.profile-left {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.profile-avatar {
  width: 200px; /* Set width */
  height: 200px; /* Set height */
  border-radius: 50%; /* Ensure it remains circular */
}

.profile-bio {
  font-size: 18px;
  color: #555;
}

.profile-right {
  padding-left: 20px;
}

.avatar-img {
  width: 30px; /* Specific size for TopNav avatar */
  height: 30px; /* Specific size for TopNav avatar */
  border-radius: 50%;
}

/* App.css */
.hero-btn {
  display: flex;
  align-items: center;
}

.hero-btn i {
  margin-right: 8px; /* Adjust spacing between icon and text */
}




/* ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■ ↓↓↓↓↓  Fonts/Text  ↓↓↓↓↓ ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■ */

@import url('https://fonts.googleapis.com/css2?family=League+Spartan:wght@100;200;300;400;500;600;700;800;900&display=swap');

.small-validation-text .invalid-feedback,
.small-validation-text .valid-feedback {
  font-size: 0.8em; /* Adjust this value as needed */
}

.text-blur {
  filter: blur(9.5px); /* Adjust the blur radius as needed */
}

.navbar {
  z-index: 1000;
}

.navbar-brand {
  display: flex;
  align-items: center;
  margin-right: 1rem; /* Adjust margin as needed */
}


.navbar-nav {
  display: flex;
  align-items: left;
  justify-content: end;
  /* width: 100%; */
}

.navbar-collapse {
  justify-content: center;
}

.navbar-toggler {
  z-index: 1001; /* Ensure the button is always clickable */
}

.navbar-toggler-icon {
  background-image: url('data:image/svg+xml;charset=utf8,%3Csvg viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg"%3E%3Cpath stroke="rgba%28255, 255, 255, 0.5%29" stroke-width="2" linecap="round" linejoin="round" d="M4 7h22M4 15h22M4 23h22"/%3E%3C/svg%3E');
}

.nav-link {
  color: #ffffff; /* This makes the text lighter when active or hovered */
}

.nav-link.active, .nav-link:hover, .nav-link:focus {
  color: #ffffff; /* This makes the text lighter when active or hovered */
}

.nav-link.active::after, .nav-link:hover::after, .nav-link:focus::after {
  content: '';
  display: block;
  margin: auto;
  width: 100%;
  background-color: #b8b8b8;
}


.top-nav-text {
  font-family: 'League Spartan', sans-serif;
  font-weight: 500;
  font-size: 15px;
  color:#b8b8b8;
  text-align: right;
  padding: 0px 40px;
 }

 .top-nav-text:hover, .dropdown-item:hover {
  color: #5e5c5c; /* Lighter grey to make the hover effect more subtle */
}

 
 .url-text {
  font-family: League+Spartan;
  font-weight: 500;
  color: #B8B8B8;
  font-size: 10px;
  border-radius: 5px;
 }

 .admin-text {
     font-family: League+Spartan;
     font-weight: 500;
     color: #B8B8B8;
     font-size: 15px;
     border-radius: 5px;
 }
 
 .toolbar-header {
     font-family: League+Spartan;
     font-weight: 600;
     color: #e1e1e1;
     font-size: 13px;
     box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
 }

  
 .toolbar-header-name {
  font-family: 'League Spartan', sans-serif;
  font-weight: 600;
  color: #e1e1e1;
  font-size: 13px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
  margin: auto;
  display: inline-flex;
  align-items: center;
}


 .toolbar-text {
     font-family: League+Spartan;
     font-weight: 400;
     color: #A7A6A6;
     font-size: 11px;
 }




 .toolbar-hover {
  color: #A7A6A6;
  transition: background-color 0.1s ease; /* Smooth transition */
  display: flex; /* Use flexbox layout */
  align-items: center; /* Center items vertically */
  justify-content: center; /* Center items horizontally */
}

/* .toolbar-hover:hover {
  background-color: rgb(31, 32, 35);
} */

 .input-field-font {
  font-family: 'League Spartan', sans-serif; /* Ensure proper font-family declaration */
  padding: 6px 12px;
  background: rgb(31, 32, 35);
  border: 1px solid rgb(60, 63, 68);
  border-radius: 4px;
  font-size: 14px;
  color: rgb(247, 248, 248);
  height: 30px;
  appearance: none;
  transition: box-shadow 0.15s ease 0s;
}

.component-title {
  font-family: League+Spartan;
  font-weight: 500;
  color: #A7A6A6;
  font-size: 15px;
}

.component-body {
  font-family: League+Spartan;
  font-weight: 400;
  color: #A7A6A6;
  font-size: 12px;
}

.component-body-small {
  font-family: League+Spartan;
  font-weight: 500;
  color: #A7A6A6;
  font-size: 11px;

  padding: 3px 5px 3px 5px;
  border-radius: 5px;
}

.component-body-date {
  font-family: League+Spartan;
  font-weight: 400;
  color: #A7A6A6;
  font-size: 11px;
  background-color:#4a4a4a;
  padding: 3px 5px 3px 5px;
  border-radius: 5px;
  text-align: start;
}

.hero-title-accent {
  font-family: League+Spartan;
  font-weight: 400;
  color: #dedede;
  font-size: 12px;
  background-color: #32333F;
  padding: 5px 10px 5px 10px;
  margin: 0px 0px 10px 0px;
  border-radius: 5px;
}

.hero-btn {
  width: 200px;
}

@media (max-width: 600px) {
  .hero-btn {
    display: none;
  }
}

/* Existing styles with added responsiveness for .hero-title */
.hero-title-switch{
  font-family: League+Spartan;
  font-weight: 800;
  color: #FFFFFF;
  font-size: 54w; /* Responsive font size */
  text-align: center;
  line-height: 1; /* Adjusted line-height for scalability */
}

/* Existing styles with added responsiveness for .hero-body */
.hero-body-switch {
  font-family: League+Spartan;
  font-weight: 400;
  color: #B8B8B8;
  font-size: 1.5vw; /* Responsive font size */
  max-width: 500px;
  text-align: left;
  line-height: 25px;
}

/* Minimum font sizes on smaller viewports */
@media (max-width: 600px) {

  .hero-title-switch {
    font-family: League+Spartan;
    font-weight: 800;
    color: #FFFFFF;
    font-size: 10vw; /* Responsive font size */
    text-align: center;
    line-height: 1; /* Adjusted line-height for scalability */
  }

  .hero-body-switch {
    font-family: League+Spartan;
    font-weight: 400;
    color: #B8B8B8;
    font-size: 4vw;
    max-width: 500px;
    text-align: left;
    line-height: 27px;
  }
}

/* Existing styles with added responsiveness for .hero-title */
.hero-title {
  font-family: League+Spartan;
  font-weight: 800;
  color: #FFFFFF;
  font-size: 6vw; /* Responsive font size */
  text-align: left;
  line-height: 1; /* Adjusted line-height for scalability */
}

/* Existing styles with added responsiveness for .hero-body */
.hero-body {
  font-family: League+Spartan;
  font-weight: 400;
  color: #B8B8B8;
  font-size: 1.5vw; /* Responsive font size */
  max-width: 500px;
  text-align: left;
}

/* Minimum font sizes on smaller viewports */
@media (max-width: 600px) {

  .hero-title {
    font-family: League+Spartan;
    font-weight: 800;
    color: #FFFFFF;
    font-size: 10vw; /* Responsive font size */
    text-align: left;
    line-height: 1; /* Adjusted line-height for scalability */
  }

  .hero-body {
    font-family: League+Spartan;
    font-weight: 400;
    color: #B8B8B8;
    font-size: 3vw;
    max-width: 500px;
    text-align: left;
  }
}

.footer-text {
  font-family: League+Spartan;
  font-weight: 400;
  color: #B8B8B8;
  font-size: 17px;
  max-width: 500px;
  text-align: left;
}

.subheader-text {
  font-family: League+Spartan;
  font-weight: 800;
  color: #FFFFFF;
  font-size: 50px;
  text-align:left;
}

.side-bar-header {
  font-family: League+Spartan;
  font-weight: 500;
  color: #FFFFFF;
  font-size: 15px;
  text-align:left;
}

.side-bar-body {
  font-family: League+Spartan;
  font-weight: 500;
  color: #FFFFFF;
  font-size: 12px;
  text-align:left;
}


.pricing-text {
  font-family: League+Spartan;
  font-weight: 500;
  color: #c0c0c0;
  font-size: 18px; /* Default font size for large screens */
  max-width: 800px;
  text-align: center;
  width: 100%;
  margin: 0 auto;
  padding: 0 15px;
}

/* Media query for tablet devices */
@media (max-width: 768px) {
  .pricing-text {
    font-size: 16px; /* Slightly smaller font size for tablet devices */
  }
}

/* Media query for mobile devices */
@media (max-width: 480px) {
  .pricing-text {
    font-size: 14px; /* Even smaller font size for mobile devices */
  }
}

.no-bullets {
  list-style-type: none;
  padding: 0;
}

.no-bullets li {
  margin: 0;
  padding: 0;
}


.d-flex.flex-column {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.doc-caps {
  font-family: League+Spartan;
  font-weight: 400;
  color: #FFFFFF;
  font-size: 15px;
  text-align:left;
}

.doc-header {
  font-family: League+Spartan;
  font-weight: 600;
  color: #dedede;
  font-size: 40px;
  text-align:left;
}

.doc-sub-header {
  font-family: League+Spartan;
  font-weight: 400;
  color: #b1b1b1;
  font-size: 17px;
  text-align:left;
  line-height: 25px;
  margin-top: 30px;
  margin-bottom: 0px;
}

.doc-body-large {
  font-family: League+Spartan;
  font-weight: 400;
  color: #7272d6;
  font-size: 18px;
  text-align:left;
  line-height: 30px;
  margin-top: 30px;
  margin-bottom: 0px;
}

.doc-body-medium {
  font-family: League+Spartan;
  font-weight: 400;
  color: #FFFFFF;
  font-size: 14px;
  text-align:left;
}

.doc-body-small {
  font-family: League+Spartan;
  font-weight: 400;
  color: #FFFFFF;
  font-size: 16px;
  text-align:left;
  line-height: 22px;
}

.no-bullets {
  list-style-type: none;
  padding-left: 0;
}

.pricing-title {
  font-family: League+Spartan;
  font-weight: 500;
  color: #FFFFFF;
  font-size: 30px;
  text-align:left;
  line-height: 30px;
}

.dashboard-title {
  font-family: League+Spartan;
  font-weight: 600;
  color: #FFFFFF;
  font-size: 40px;
  text-align:left;
  margin: 0px 0px 0px 40px;
}

.forgot-password-link {
  font-family: 'League Spartan', sans-serif;
  font-weight: 400;
  color: #FFFFFF;
  font-size: 14px;
  text-align: left;
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  cursor: pointer;
  text-decoration: underline;
  display: inline;
  outline: none;
}

/* Additional: Style for when the user hovers over the link */
.forgot-password-link:hover {
  color: #BBBBBB; /* Optional: Change color on hover */
  text-decoration: none; /* Optional: Change or remove text decoration on hover */
}


/* ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■ ↓↓↓↓↓  Containers/Images/Elements  ↓↓↓↓↓ ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■ */



.pricing-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

/* Assuming you are using Bootstrap, you can adjust the class directly in your JSX if needed. */
/* For custom adjustments, you might add these styles: */
@media (max-width: 991px) {
  .pricing-box {
    flex: 0 0 50%; /* Each box will take half of the container's width on medium screens */
    max-width: 50%; /* Ensures that the max-width does not exceed 50% */
  }
}

@media (max-width: 767px) {
  .pricing-box {
    flex: 0 0 100%; /* Each box will take full width of the container on small screens */
    max-width: 80%; /* Ensures that the max-width does not exceed 100% */
  }
}


.bg-main {
  background: rgb(13, 13, 19);
}

.pricing-box {
  margin-bottom: 50px;
  background: rgb(18,17,19);
  background: linear-gradient(45deg, rgba(18,17,19,1) 0%, rgba(37,35,40,1) 38%, rgba(18,19,20,1) 100%);
  padding: 15px 25px 15px 25px;
  border-radius: 20px;
  border: 0.5px solid #45414a; /* Added border */
  box-shadow: rgba(0, 0, 0, 0.06) 0px 2px 4px 0px inset;
  box-shadow: rgba(0, 0, 0, 0.56) 0px 22px 70px 4px;
}

.box-shadow {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

/* .dashboard-item {
  height: 72px;
  display: flex;
  align-items: center;
  overflow: hidden;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
} */

.dashboard-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  margin-bottom: 16px;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.dashboard-left {
  display: flex;
  align-items: center;
  gap: 16px;
  flex: 0 1 auto;
}

.dashboard-right {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 16px;
  flex: 1;
  width: 80%;
}

.dashboard-item-text {
  text-align: center;
}

.dashboard-item-icon {
  height: 40px;
  object-fit: cover;
  max-width: 40px;
}

.dashboard-item-title {
  margin: 0;
  font-size: 1rem;
  white-space: nowrap;
}

.dashboard-item-trash {
  cursor: pointer;
}



.messy-styling {
  height: 72px;
}

.dashboard-item img {
  max-height: 100%;
  width: auto;
  object-fit: cover;
}

.trashcan-icon {
  transition: filter 0.3s;
}

.trashcan-icon:hover {
  filter: brightness(0.3);
}

@media (max-width: 768px) {
  .trashcan-icon {
    display: none;
  }
}


.bg-box {
    background: rgb(25,25,34);
    background: radial-gradient(circle, rgba(25,25,34,1) 0%, rgba(21,21,28,1) 100%);
    min-width: 400px;
    box-shadow: 0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22);
}

.divider-line {
    border-bottom: 1px solid #26262D; /* Thin bottom border */
}

.top-nav {
  background: rgb(13, 13, 19);
  z-index: 1000; /* Ensures the navbar is above other content */
  position: relative; /* Adjust based on your layout. Could be 'absolute' or 'fixed' at the top */
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 15px; /* Adjust padding as needed */
}

.canvas-toggle-light {
  border-radius: 5px;
  background-color: white;
  height: 70px;
  width: 70px;
}

.canvas-toggle-dark {
  border-radius: 5px;
  background-color: black;
  height: 70px;
  width: 70px;
}

.canvas-toggle-transparent {
  border-radius: 5px;
  height: 70px;
  width: 70px;
  background-size: cover; /* Ensure the background covers the div */
  background-position: center; /* Center the background image */
  display: inline-block; /* This makes the div's size adjust to its content */
}

.toolbar-bg {
  background: rgb(25,25,34);
  background: radial-gradient(circle, rgba(25,25,34,1) 0%, rgba(21,21,28,1) 100%);
  border-radius: 10px;
}

.dashboard-manager {
  max-width: 1200px;
  min-height: 800px;
  background: rgb(13, 13, 19);
}

.bg-box-component {
  background: rgb(25, 25, 34);
  background: radial-gradient(circle, rgba(25, 25, 34, 1) 0%, rgba(21, 21, 28, 1) 100%);
  min-width: 400px;
  border-radius: 10px;
  /* transition: border 0.3s ease-in-out; Smooth transition for the border */
}

.bg-box-component:hover {
  background: rgb(40, 40, 54);
  background: radial-gradient(circle, rgba(40, 40, 54, 1) 0%, rgba(35, 35, 47, 1) 100%);
  /* border: 1px solid #454545; Change the border color as needed */
}

.footer-bg {
  background: rgb(25,25,34);
  background: radial-gradient(circle, rgba(25,25,34,1) 0%, rgba(21,21,28,1) 100%);
}

.customOverlay {
  background: rgba(36, 123, 160, 0.7);
}
.customModal {
  background: rgb(25,25,34);
  max-width: 300px;
  width: 100%;
}

.bg-box {
  background: rgb(25,25,34);
  background: radial-gradient(circle, rgba(25,25,34,1) 0%, rgba(21,21,28,1) 100%);
  min-width: 400px;
}

.radial-main {
  width: 225px;
  height: 225px;
  padding: 20px;
  max-width: 400px;
  min-width: 100px;
}

.main-img-container {
  overflow: hidden;
  position: relative;
}

.thumbnail-container {
  overflow: hidden;
}

.bar-main {
  width: 400px;
  height: auto;
  padding: 40px;
  max-width: 400px;
  min-width: 100px;
}

@media (max-width: 1000px) {
  .radial-main {
    width: 75%;
    height: auto;
  }
}

@keyframes fadeBorder {
  0% {
    border-color: transparent;
  }
  50% {
    border-color: #00ff00; /* Green border color */
  }
  100% {
    border-color: transparent;
  }
}


.url-container {
  border: 2px solid transparent; /* Initial transparent border */
}

.url-container.fade-border {
  animation: fadeBorder 1s ease-in-out; /* Apply the animation */
}

.add-new-asset-panel {
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  border: 2px dashed #ccc;
  color: #ccc;
}

.add-new-asset-panel:hover {
  border: 2px dashed #4caf50; /* Change the border color to green */
  color: #4caf50; /* Change the text color to green */
}


.button-container img {
  width: 100%; /* Ensures buttons scale with the container */
  height: auto; /* Maintains aspect ratio */
}

.main-img-container {
  border-radius: 10px;
}

.arrow-main {
  width: 40%;
  height: 40%;
}

.arrow-icon {
  margin-right: 10px;
}

.dashhboard-bg {
  background: rgb(40,40,50);
  background: linear-gradient(47deg, rgba(40,40,50,1) 0%, rgba(65,65,78,0.8883928571428571) 50%, rgba(40,40,50,1) 100%);
  border-radius: 10px;
}

.card-container {
  background: rgb(23,23,27);
  background: linear-gradient(45deg, rgba(23,23,27,1) 0%, rgba(32,31,34,1) 38%, rgba(23,23,27,1) 100%);
  border-radius: 0px;
  /* border: 0.5px solid #45414a; */
  box-shadow: rgba(0, 0, 0, 0.06) 0px 2px 4px 0px inset;
  box-shadow: rgba(0, 0, 0, 0.56) 0px 22px 70px 4px;
  margin: 20px;
  width:75%;
}

.login-container {
  background: rgb(18,17,19);
  background: linear-gradient(45deg, rgba(18,17,19,1) 0%, rgba(37,35,40,1) 38%, rgba(18,19,20,1) 100%);
  padding: 50px;
  border-radius: 20px;
  border: 0.5px solid #45414a; /* Added border */
  box-shadow: rgba(0, 0, 0, 0.06) 0px 2px 4px 0px inset;
  box-shadow: rgba(0, 0, 0, 0.56) 0px 22px 70px 4px;
  /* width: 70%; */
  min-width: 400px;
  max-width: 400px;
}

.react-responsive-modal {
  background: rgb(18,17,19);
  background: linear-gradient(45deg, rgba(18,17,19,1) 0%, rgba(37,35,40,1) 38%, rgba(18,19,20,1) 100%);
  max-width: 1200px;
}

.signup-container {
  background: rgb(18,17,19);
  background: linear-gradient(45deg, rgba(18,17,19,1) 0%, rgba(37,35,40,1) 38%, rgba(18,19,20,1) 100%);
  /* margin-top: 20px; */
  padding: 50px;
  border-radius: 20px;
  border: 0.5px solid #45414a; /* Added border */
  box-shadow: rgba(0, 0, 0, 0.06) 0px 2px 4px 0px inset;
  box-shadow: rgba(0, 0, 0, 0.56) 0px 22px 70px 4px;
  /* width: 70%; */
  min-width: 400px;
  max-width: 550px;
}


.gradient-img-container {
background: rgb(25, 25, 34);
  background: radial-gradient(circle, rgba(25, 25, 34, 1) 0%, rgba(21, 21, 28, 1) 100%);
  border-radius: 20px;
  border: 0.5px solid #45414a; /* Added border */
  box-shadow: rgba(0, 0, 0, 0.06) 0px 2px 4px 0px inset;
  box-shadow: rgba(0, 0, 0, 0.56) 0px 22px 70px 4px;
  width: 350px;
}

@media (max-width: 1000px) {
  .gradient-img-container {
   max-height: auto;
  }
}

.gradient-arrow-container {
background: rgb(25, 25, 34);
  background: radial-gradient(circle, rgba(25, 25, 34, 1) 0%, rgba(21, 21, 28, 1) 100%);
  border-radius: 20px;
  border: 0.5px solid #45414a; /* Added border */
  box-shadow: rgba(0, 0, 0, 0.06) 0px 2px 4px 0px inset;
  box-shadow: rgba(0, 0, 0, 0.56) 0px 22px 70px 4px;
  width: 350px;
  min-width: 350px;
  min-height: 440px;
}

@media (max-width: 1000px) {
  .gradient-arrow-container {
   max-height: 400px;
   max-width: 350px;
  }
}

.gradient-container {
background: rgb(25, 25, 34);
  background: radial-gradient(circle, rgba(25, 25, 34, 1) 0%, rgba(21, 21, 28, 1) 100%);
  border-radius: 20px;
  border: 0.5px solid #45414a; /* Added border */
  box-shadow: #0000000f 0px 2px 4px 0px inset;
  box-shadow: #0000008f 0px 22px 70px 4px;
  width: 350px;
}
  @media (max-width: 750px) {
    .gradient-container {
      min-width: 350px; /* Ensure the container does not shrink below 350px on smaller screens */
      height: 100%;
    }
}

.gradient-bar-container {
background: rgb(25, 25, 34);
  background: radial-gradient(circle, rgba(25, 25, 34, 1) 0%, rgba(21, 21, 28, 1) 100%);
  border-radius: 20px;
  border: 0.5px solid #45414a; /* Added border */
  box-shadow: #0000000f 0px 2px 4px 0px inset;
  box-shadow: #0000008f 0px 22px 70px 4px;
  width: 350px;
  height:400px;
  min-width: 350px;
}
  @media (max-width: 750px) {
    .gradient-container {
      min-width: 350px;
      height: 300px; /* Ensure the container does not shrink below 350px on smaller screens */
    }
  }

.gradient-toolbar {
  background: rgb(49,49,61);
  background: radial-gradient(circle, rgba(49,49,61,1) 0%, rgba(56,56,70,1) 51%, rgba(49,49,61,1) 100%);
  border-radius: 5px;
  /* border: 0.5px solid #45414a; */
  box-shadow: rgba(0, 0, 0, 0.06) 0px 2px 4px 0px inset;
  box-shadow: rgba(0, 0, 0, 0.56) 0px 22px 70px 4px;
}

.radial-container {
height: 573.44px;
}

.arrow-main {
  min-width: 200px;
}

.main-graphic-container {
  max-width:500px;
}


.sub-nav.side-bar-body {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-in-out;
}

.sub-nav.side-bar-body.open {
  max-height: 200px; /* Adjust as necessary based on your content */
}

.sub-nav.side-bar-body.transitioning {
  max-height: 0;
}

/* ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■ ↓↓↓↓↓  Input Fields  ↓↓↓↓↓ ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■ */

/* Add these styles to your App.css */

/* Valid state styles */
.is-valid {
  border-color: #28a745;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%2328a745' viewBox='0 0 8 8'%3e%3cpath d='M6.564 1.226c.15-.206.44-.26.646-.11.206.15.26.44.11.646l-4 5.5a.5.5 0 01-.748.033l-2-2.5a.5.5 0 01.78-.625l1.72 2.148 3.492-4.592z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

/* Invalid state styles */
.is-invalid {
  border-color: #dc3545;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23dc3545' viewBox='0 0 12 12'%3e%3cpath d='M5.5 0a5.5 5.5 0 105.5 5.5A5.506 5.506 0 005.5 0zm0 9.75a.75.75 0 110-1.5.75.75 0 010 1.5zm1.53-5.97a.75.75 0 00-1.06-1.06L5.5 4.44 4.03 2.97a.75.75 0 00-1.06 1.06L4.44 5.5l-1.47 1.47a.75.75 0 001.06 1.06L5.5 6.56l1.47 1.47a.75.75 0 001.06-1.06L6.56 5.5l1.47-1.47z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

/* Add animations for valid and invalid states */
@keyframes valid-feedback {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes invalid-feedback {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.is-valid ~ .valid-feedback {
  display: block;
  animation: valid-feedback 0.5s ease-in-out;
}

.is-invalid ~ .invalid-feedback {
  display: block;
  animation: invalid-feedback 0.5s ease-in-out;
}


.readonly-input {
  background-color: #f0f0f0; /* Light grey background */
  color: #6c757d; /* Grey text color */
  /* cursor: not-allowed; */
}


.input-field:focus {
  outline: none;
  box-shadow: 0 0 0 2px rgba(114, 114, 214, 1);
  background: rgb(31, 32, 35); /* Maintain the initial background color */
}

/* Add an extra element around your input field to simulate the gradient border */
.input-field-wrapper {
  border-radius: 5px; /* Slightly larger than the input-field to show the gradient border */
  background: linear-gradient(90deg, rgba(114,114,214,1) 0%, rgba(114,114,214,1) 46%, rgba(68,68,117,1) 100%);
  padding: 1px; /* This padding effectively becomes the gradient border */
}

.input-field-font:focus {
outline: none;
box-shadow: 0 0 0 2px rgba(114, 114, 214, 1);
background: rgb(31, 32, 35); /* Maintain the initial background color */
}

/* Add an extra element around your input field to simulate the gradient border */
.input-field-font-wrapper {
border-radius: 5px; /* Slightly larger than the input-field to show the gradient border */
background: linear-gradient(90deg, rgba(114,114,214,1) 0%, rgba(114,114,214,1) 46%, rgba(68,68,117,1) 100%);
padding: 1px; /* This padding effectively becomes the gradient border */
}

.range-input-field {
  max-width: 60px;
  font-family: 'League Spartan', sans-serif; /* Ensure proper font-family declaration */
  padding: 6px 12px;
  background: rgb(31, 32, 35);
  border: 1px solid rgb(60, 63, 68);
  border-radius: 4px;
  font-size: 13px;
  color: rgb(247, 248, 248);
  height: 25px;
  appearance: none;
  transition: box-shadow 0.15s ease 0s;
  font-weight: 300;
}

.range-input-field:focus {
  outline: none;
  box-shadow: 0 0 0 2px rgba(114, 114, 214, 1);
  background: rgb(31, 32, 35); /* Maintain the initial background color */
}

/* Add an extra element around your input field to simulate the gradient border */
.range-input-field-wrapper {
  border-radius: 5px; /* Slightly larger than the range-input-field to show the gradient border */
  background: linear-gradient(90deg, rgba(114,114,214,1) 0%, rgba(114,114,214,1) 46%, rgba(68,68,117,1) 100%);
  padding: 1px; /* This padding effectively becomes the gradient border */
}


.asset-value-input-field {
  max-width: 60px;
  font-family: 'League Spartan', sans-serif; /* Ensure proper font-family declaration */
  padding: 6px 12px;
  background: #1f2023;
  border: 1px solid rgb(60, 63, 68);
  border-radius: 4px;
  font-size: 20px;
  color: rgb(247, 248, 248);
  height: 45px;
  appearance: none;
  transition: box-shadow 0.15s ease 0s;
}

.asset-value-input-field:focus {
  outline: none;
  box-shadow: 0 0 0 2px rgba(114, 114, 214, 1);
  background: rgb(31, 32, 35); /* Maintain the initial background color */
}

/* Add an extra element around your input field to simulate the gradient border */
.asset-value-input-field-wrapper {
  border-radius: 5px; /* Slightly larger than the diameter-input-field to show the gradient border */
  background: linear-gradient(90deg, rgba(114,114,214,1) 0%, rgba(114,114,214,1) 46%, rgba(68,68,117,1) 100%);
  padding: 1px; /* This padding effectively becomes the gradient border */
}

.diameter-input-field {
  max-width: 60px;
  font-family: 'League Spartan', sans-serif; /* Ensure proper font-family declaration */
  padding: 6px 12px;
  background: #1f2023;
  border: 1px solid rgb(60, 63, 68);
  border-radius: 4px;
  font-size: 13px;
  color: rgb(247, 248, 248);
  height: 25px;
  appearance: none;
  transition: box-shadow 0.15s ease 0s;
}

.diameter-input-field:focus {
  outline: none;
  box-shadow: 0 0 0 2px rgba(114, 114, 214, 1);
  background: rgb(31, 32, 35); /* Maintain the initial background color */
}

/* Add an extra element around your input field to simulate the gradient border */
.diameter-input-field-wrapper {
  border-radius: 5px; /* Slightly larger than the diameter-input-field to show the gradient border */
  background: linear-gradient(90deg, rgba(114,114,214,1) 0%, rgba(114,114,214,1) 46%, rgba(68,68,117,1) 100%);
  padding: 1px; /* This padding effectively becomes the gradient border */
}

.no-pointer-events {
  pointer-events: none;
}


.asset-name-field {
  max-width: 200px;
  font-family: 'League Spartan', sans-serif; /* Ensure proper font-family declaration */
  padding: 6px 12px;
  background: rgb(31, 32, 35);
  border: 1px solid rgb(60, 63, 68);
  border-radius: 4px;
  font-size: 15px;
  color: rgb(247, 248, 248);
  height: 30px;
  /* appearance: none; */
  transition: box-shadow 0.15s ease 0s;
}

.asset-name-field:focus {
  outline: none;
  box-shadow: 0 0 0 2px rgba(114, 114, 214, 1);
  color: rgb(247, 248, 248);
  background: rgb(31, 32, 35); /* Maintain the initial background color */
}



/* Add an extra element around your input field to simulate the gradient border */
.asset-name-field-wrapper {
  border-radius: 5px; /* Slightly larger than the diameter-input-field to show the gradient border */
  background: linear-gradient(90deg, #7373d6 0%, rgba(114,114,214,1) 46%, rgba(68,68,117,1) 100%);
  padding: 1px; /* This padding effectively becomes the gradient border */
}

.input-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Aligns items to the left */
  margin-bottom: 1rem; /* Adds some space between the input fields */
}

.input-field-login {
  width: 100%; /* Ensures the input field takes up the full width of its parent */
  margin-top: 0.5rem; /* Adds some space between the label and the input field */
  font-family: 'League Spartan', sans-serif; /* Ensure proper font-family declaration */
  padding: 6px 12px;
  background: rgb(31, 32, 35);
  border: 1px solid rgb(60, 63, 68);
  border-radius: 4px;
  font-size: 15px;
  color: rgb(247, 248, 248);
  height: 35px;
  appearance: none;
  transition: box-shadow 0.15s ease 
}

.input-field-asset-name {
  width: 200px; /* Ensures the input field takes up the full width of its parent */
  margin-top: 0.5rem; /* Adds some space between the label and the input field */
  font-family: 'League Spartan', sans-serif; /* Ensure proper font-family declaration */
  padding: 6px 12px;
  background: rgb(31, 32, 35);
  border: 1px solid rgb(60, 63, 68);
  border-radius: 4px;
  font-size: 15px;
  color: rgb(247, 248, 248);
  height: 35px;
  appearance: none;
  transition: box-shadow 0.15s ease 
}

.input-field-code {
  width: 35px; /* Ensures the input field takes up the full width of its parent */
  margin-top: 0.5rem; /* Adds some space between the label and the input field */
  font-family: 'League Spartan', sans-serif; /* Ensure proper font-family declaration */
  padding: 6px 12px;
  background: rgb(31, 32, 35);
  border: 1px solid rgb(60, 63, 68);
  border-radius: 4px;
  font-size: 15px;
  color: rgb(247, 248, 248);
  height: 35px;
  appearance: none;
  transition: box-shadow 0.15s ease 
}

.input-field {
  width: 50%; /* Ensures the input field takes up the full width of its parent */
  margin-top: 0.5rem; /* Adds some space between the label and the input field */
  font-family: 'League Spartan', sans-serif; /* Ensure proper font-family declaration */
  padding: 6px 12px;
  background: rgb(31, 32, 35);
  border: 1px solid rgb(60, 63, 68);
  border-radius: 4px;
  font-size: 13px;
  color: rgb(247, 248, 248);
  height: 25px;
  appearance: none;
  transition: box-shadow 0.15s ease 
}

.form-label {
  margin-left: 0.3rem; /* Adjusts label positioning to align with the input field */
  margin-bottom: 0; /* Removes default margin below the label */
}

.input-label-text {
  font-family: League+Spartan;
  font-weight: 400;
  color: #FFFFFF;
  font-size: 12px;
  text-align:left;
  padding-bottom: 5px;
}

.underscore-input {
    border: none;
    border-bottom: 1px solid #bcbbbb; /* Creates the underscore effect */
    outline: none; /* Removes the default focus highlight */
    width: 35px; /* Ensures the input field takes up the full width of its parent */
    margin-top: 0.5rem; /* Adds some space between the label and the input field */
    font-family: 'League Spartan', sans-serif; /* Ensure proper font-family declaration */
    background: rgb(31, 32, 35);
    font-size: 13px;
    color: rgb(247, 248, 248);
    height: 25px;
    appearance: none;
    transition: box-shadow 0.15s ease 
  }
  
  .underscore-input:focus {
    border-bottom-color: #555; /* Darkens the underscore on focus */
  }

  .api-key-container {
    background:#343438;
    border-radius: 5px;
    padding: 5px 30px 5px 30px;
  }

.add-new-asset-panel {
    border-radius: 5px;
    padding: 5px 30px 5px 30px;
    border: dashed;
    color: rgb(155, 155, 155);
    min-height:72px;
    cursor: pointer;
}

.asset-checkbox {
  height: 200%;
  width: auto;
}

  .url-container {
    background:#343438;
    border-radius: 5px;
    padding: 10px 30px 10px 30px;
  }

  .api-key-text {
    font-family: League+Spartan;
    font-weight: 400;
    color: #A7A6A6;
    font-size: 12px;
  }

  @keyframes pulse-border {
    0% {
      border-color: #7373d6;
    }
    50% {
      border-color: transparent;
    }
    100% {
      border-color: #7373d6;
    }
  }
  
  .api-key {
    border-radius: 5px;
    padding: 15px 20px;
    border: solid 0.5px;
    border-color: #7373d6;
    background: radial-gradient(circle, rgba(25, 25, 34, 1) 0%, rgba(21, 21, 28, 1) 100%);
    animation: pulse-border 4s infinite;
  }
  
/* ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■ ↓↓↓↓↓  Buttons/Selectors/Links  ↓↓↓↓↓ ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■ */

.back-arrow-btn {
  margin: 0px;
  padding: 0px;
  height: 50px;
  width: 50px;
}

.copy-code-icon {
  width: auto;
  height:60%;
}

.button-row {
  display: flex;
  justify-content: center; /* Aligns buttons in the center horizontally */
  gap: 10px; /* Provides space between the buttons */
}

.btn {
  flex: 1; /* Each button takes equal width */
}



.clickable {
  cursor: pointer;
}

.question-icon {
  height: 15px;
  width: auto;
}

.icon-hover {
  transition: filter 0.3s ease; /* Smooth transition for the filter effect */
}

.icon-hover:hover {
  filter: brightness(50%); /* Darken the icon by reducing brightness */
}

.custom-link {
  text-decoration: none;
  display: block;
  color: #a3a3b7;
  font-size: 13px;
}

.custom-link:hover {
  text-decoration: none; /* Optional: Add underline on hover if needed */
  color: #d4d3d3;
  background-color: rgb(31, 32, 36);
  border-radius: 5px;
}


.square-color-picker {
  width: 20px; /* Set to desired square size */
  height: 20px; /* Same as width to make it square */
  border: 10px solid transparent; /* Initially, the border is transparent */
  padding: 0; /* Removes padding */
  cursor: pointer; /* Changes cursor on hover */
}

.square-color-picker:hover {
  border-color: rgb(90, 93, 103); /* Subtle grey border on hover */
}


.reset-btn:hover, .render-btn:hover, .create-btn:hover, .hero-doc-btn:hover, .hero-pricing-btn:hover, .signup-btn:hover, .confirm-btn:hover {
  filter: brightness(70%);
  transition: filter 0.1s ease;
}

.copy-code-icon {
  height: 18px;
}

/* App.css */
.button-underline {
  height: 2px;
  background-color: white;
  width: 100%;
}

.edit-icon {
  width: 25px;
  height: auto;
  color:rgb(122, 122, 126);
}

.update-asset-btn {
  margin: 10px;
  padding: 10px 10px;
  text-align: center;
  text-transform: uppercase;
  transition: 0.5s;
  background-size: 200% auto;
  color: #a8a8a8;
  border-radius: 5px;
  display: block;
  border: 0px;
  font-family: League+Spartan;
  font-weight: 500;
  font-size: 14px;
  /* box-shadow: 0 0 0 2px rgb(65, 65, 123); */
  background: rgb(31, 32, 35);
  cursor: pointer;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.update-asset-btn:hover {
  background-position: right center;
  /* change the direction of the change here */
  color: #fff;
  text-decoration: none;
  box-shadow: 0 0 0 2px rgba(114, 114, 214, 1);
  background: #393939;
}

/* .update-asset-btn:active {
  transform: scale(0.55);
} */

.hero-doc-btn {
  margin: 10px;
  padding: 5px 10px;
  text-align: center;
  text-transform: uppercase;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  border-radius: 10px;
  display: block;
  border: 0px;
  font-family: League+Spartan;
  font-weight: 600;
  font-size: 15px;
  box-shadow: 0px 0px 14px -7px #000000;
  background: #7272d6;
  background: radial-gradient(circle, rgba(114,114,214,1) 0%, rgba(99,99,179,1) 46%, rgba(93,93,167,1) 100%);
  cursor: pointer;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.hero-doc-btn:hover {
  background-position: right center;
  /* change the direction of the change here */
  color: #fff;
  text-decoration: none;
}

.hero-doc-btn:active {
  transform: scale(0.95);
}

.hero-pricing-btn {
  margin: 10px;
  padding: 5px 10px;
  text-align: center;
  text-transform: uppercase;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  border-radius: 10px;
  display: block;
  border: 0px;
  font-family: League+Spartan;
  font-weight: 600;
  font-size: 15px;
  outline: none;
  box-shadow: 0 0 0 2px rgba(114, 114, 214, 1);
  background: rgb(31, 32, 35);
  cursor: pointer;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.hero-pricing-btn:hover {
  background-position: right center;
  /* change the direction of the change here */
  color: #fff;
  text-decoration: none;
}

.hero-pricing-btn:active {
  transform: scale(0.95);
}

/* Media query to hide buttons on small screens */
@media (max-width: 600px) {
  .hero-doc-btn,
  .hero-pricing-btn {
    display: none;
  }
}


.reset-btn {
  margin: 10px;
  padding: 5px 10px;
  text-align: center;
  text-transform: uppercase;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  border-radius: 10px;
  display: block;
  border: 0px;
  font-family: League+Spartan;
  font-weight: 600;
  font-size: 15px;
  box-shadow: 0px 0px 14px -7px #000000;
  background: #7272d6;
  background: radial-gradient(circle, #7272d6 0%, #6363b3 46%, #5d5da7 100%);
  cursor: pointer;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.reset-btn:hover {
  background-position: right center;
  /* change the direction of the change here */
  color: #fff;
  text-decoration: none;
}

.reset-btn:active {
  transform: scale(0.95);
}

.signup-btn {
  margin: 10px;
  padding: 5px 10px;
  text-align: center;
  text-transform: uppercase;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  border-radius: 10px;
  display: block;
  border: 0px;
  font-family: 'League Spartan', sans-serif;
  font-weight: 600;
  font-size: 15px;
  line-height: 2; /* Ensure consistent line height */
  box-shadow: 0px 0px 14px -7px #000000;
  background: radial-gradient(circle, rgba(114,114,214,1) 0%, rgba(99,99,179,1) 46%, rgba(93,93,167,1) 100%);
  cursor: pointer;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  white-space: nowrap; /* Prevent text from wrapping */
}

.signup-btn:hover {
  background-position: right center; /* change the direction of the change here */
  color: #fff;
  text-decoration: none;
}

.signup-btn:active {
  transform: scale(0.95);
}

.render-btn {
  margin: 10px;
  padding: 5px 10px;
  text-align: center;
  text-transform: uppercase;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  border-radius: 10px;
  display: block;
  border: 0px;
  font-family: League+Spartan;
  font-weight: 600;
  font-size: 15px;
  outline: none;
  box-shadow: 0 0 0 2px #7272d6;
  background: rgb(31, 32, 35);
  cursor: pointer;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.render-btn:hover {
  background-position: right center;
  /* change the direction of the change here */
  color: #fff;
  text-decoration: none;
}

.render-btn:active {
  transform: scale(0.95);
}

.copy-url-btn {
  min-width: 150px;
  margin: 10px;
  text-align: center;
  text-transform: uppercase;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  border-radius: 10px;
  display: block;
  border: 0px;
  font-family: League+Spartan;
  font-weight: 600;
  font-size: 15px;
  outline: none;
  box-shadow: 0 0 0 2px #7272d6;
  background: rgb(31, 32, 35);
  cursor: pointer;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.copy-url-btn:hover {
  background-position: right center;
  /* change the direction of the change here */
  color: #fff;
  text-decoration: none;
}

.copy-url-btn:active {
  transform: scale(0.95);
}

.logout-btn {
  max-width: 100px;
  margin: 10px;
  padding: 5px 10px;
  text-align: center;
  text-transform: uppercase;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  border-radius: 5px;
  display: block;
  border: 0px;
  font-family: League+Spartan;
  font-weight: 600;
  font-size: 15px;
  outline: none;
  box-shadow: 0 0 0 2px #7272d6;
  background: rgb(31, 32, 35);
  cursor: pointer;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.logout-btn:hover {
  background-position: right center;
  /* change the direction of the change here */
  color: #fff;
  text-decoration: none;
}

.logout-btn:active {
  transform: scale(0.95);
}

.login-btn-top {
  max-width: 100px;
  padding: 5px 10px;
  text-align: center;
  text-transform: uppercase;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  border-radius: 5px;
  display: block;
  border: .5px;
  font-family: League+Spartan;
  font-weight: 400;
  font-size: 14px;
  background: none;
  /* box-shadow: 0 0 0 2px #282832;
  background: rgb(31, 32, 35); */
  cursor: pointer;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.login-btn-top:hover {
  background-position: right center;
  /* change the direction of the change here */
  color: #616161;
  text-decoration: none;
}

.login-btn-top:active {
  transform: scale(0.95);
}

.signup-btn-top {
  max-width: 100px;
  padding: 5px 10px;
  text-align: center;
  text-transform: uppercase;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  border-radius: 5px;
  display: block;
  border: none;
  font-family: League+Spartan;
  font-weight: 400;
  font-size: 14px;
  background: none;
  cursor: pointer;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.signup-btn-top:hover {
  background-position: right center;
  /* change the direction of the change here */
  color: #616161;
  text-decoration: none;
}

.signup-btn-top:active {
  transform: scale(0.95);
}

.confirm-btn {
  margin: 10px;
  padding: 5px 10px;
  text-align: center;
  text-transform: uppercase;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  border-radius: 10px;
  display: block;
  font-family: League+Spartan;
  font-weight: 600;
  font-size: 15px;
  outline: none;
  border:none;
  background: rgb(18,98,31);
  background: linear-gradient(130deg, rgba(18,98,31,1) 0%, rgba(24,131,42,1) 48%, rgba(19,105,34,1) 100%);
  cursor: pointer;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.confirm-btn:hover {
  background-position: right center;
  /* change the direction of the change here */
  color: #fff;
  text-decoration: none;
}

.confirm-btn:active {
  transform: scale(0.95);
}


.create-btn {
  margin: 10px;
  padding: 10px 20px;
  text-align: center;
  text-transform: uppercase;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  border-radius: 10px;
  display: block;
  border: none;
  font-family: 'League Spartan', sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 2; /* Ensure consistent line height */
  box-shadow: 0px 0px 14px -7px #000000;
  background: rgb(18,98,31);
  background: linear-gradient(130deg, rgba(18,98,31,1) 0%, rgba(24,131,42,1) 48%, rgba(19,105,34,1) 100%);
  cursor: pointer;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  white-space: nowrap; /* Prevent text from wrapping */
}

.create-btn:hover {
  background-position: right center;
  /* change the direction of the change here */
  color: #fff;
  text-decoration: none;
}

.create-btn:active {
  transform: scale(0.95);
}


.cancel-btn {
  min-width: 40px;
  margin: 10px;
  padding: 10px 20px;
  text-align: center;
  text-transform: uppercase;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  border-radius: 10px;
  display: block;
  border: none;
  font-family: 'League Spartan', sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 2; /* Ensure consistent line height */
  box-shadow: 0px 0px 14px -7px #000000;
  background: rgb(150, 8, 8);
  background: linear-gradient(130deg, rgb(96, 8, 8) 0%, rgb(202, 3, 39) 48%, rgb(170, 2, 25) 100%);
  cursor: pointer;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  white-space: nowrap; /* Prevent text from wrapping */
}

.cancel-btn:hover {
  color: #fff;
  text-decoration: none;
}

/* .cancel-btn:active {
  transform: scale(0.95);
} */

.coming-soon-btn {
  margin: 10px;
  padding: 5px 10px;
  text-align: center;
  text-transform: uppercase;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  border-radius: 10px;
  display: block;
  border: 0px;
  font-family: League+Spartan;
  font-weight: 600;
  font-size: 15px;
  outline: none;
  box-shadow: 0 0 0 2px rgba(114, 114, 214, 1);
  background: rgb(31, 32, 35);
  cursor: not-allowed; /* Change cursor to indicate that the button is not usable */
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  opacity: 0.5; /* Reduce the opacity to make it appear faded */
  pointer-events: none; /* Disables all interactions like click, hover */
}


.radio-btn {
  border: solid;
  background: rgb(31, 32, 35);
  cursor: pointer;
  accent-color: #7272D6;
}

.ring-thickness-btn {
  width: 40px;
  height: 40px;
  border: 1px solid rgb(60, 63, 68);
  border-radius: 4px;
  background: none;
}

.ring-thickness-btn img {
  width: 100%;
  height: 100%;
}

.ring-thickness-btn.active {
  border-color: rgb(114, 114, 214);
}

.ring-thickness-btn.active img {
  filter: brightness(80%);
}

.bar-thickness-btn {
  width: 50px;
  height: 38px;
  border: 1px solid rgb(60, 63, 68);
  border-radius: 4px;
  background: none;
  padding: 0;
  margin: 0;
}

.bar-thickness-btn img {
  width: 100%;
  height: 100%;
}

.bar-thickness-btn.active {
  border-color: rgb(114, 114, 214);
}

.bar-thickness-btn.active img {
  filter: brightness(80%);
}

.new-asset-btn {
  width: 70px;
  height: 70px;
  border: 1px solid rgb(60, 63, 68);
  border-radius: 4px;
  background: none;
  margin: 0 10px;
}

.new-asset-btn img {
  width: 100%;
  height: 100%;
}

.new-asset-btn.active {
  border-color: rgb(114, 114, 214);
}

.new-asset-btn.active img {
  filter: brightness(70%);
}

.template-btn {
  width: 30px;
  height: auto;
  border: 1px solid rgb(60, 63, 68);
  border-radius: 4px;
  background: none;
  flex: 0 0 calc(20% - 10px); /* Adjust the 10px to account for any margin or padding */
  margin: 5px; /* This provides a little space between buttons; adjust as needed */
  height: auto;
  padding: 5px;
}

.template-btn img {
  width: 100%;
  height: auto;
}

.template-btn.active {
  border-color: rgb(114, 114, 214);
}

.template-btn.active img {
  filter: brightness(70%);
}

.col-12.d-flex {
  flex-wrap: wrap;
  justify-content: center; /* Keeps items centered; already set but included for clarity */
}

.submit-btn {
  margin: 10px;
  padding: 5px 10px;
  text-align: center;
  text-transform: uppercase;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  border-radius: 10px;
  display: block;
  border: 0px;
  font-family: League+Spartan;
  font-weight: 600;
  font-size: 11px;
  box-shadow: 0px 0px 14px -7px #000000;
  background: rgb(114,114,214);
  background: radial-gradient(circle, rgba(114,114,214,1) 0%, rgba(99,99,179,1) 46%, rgba(93,93,167,1) 100%);
  cursor: pointer;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.submit-btn:hover {
  background-position: right center;
  /* change the direction of the change here */
  color: #fff;
  text-decoration: none;
}

.submit-btn:active {
  transform: scale(0.95);
}

.name-change {
  background-color: rgb(31, 32, 35);
}

/* Add hover state */
.name-change:hover {
  border: 1px solid rgba(255, 255, 255, 0.5); /* Faint grey thin border */
}

.edit-asset-name-btn {
  width: 22px;
  height: auto;
  padding: 1px;
}
    
.download-btn {
  padding: 0px;
  margin: 0px;
}

.size-selector {
    background-color: #0F0F12;
    color: #A7A6A6;
    border: none;
    border-radius: 5px;
}

.active-button {
    border: 1px solid grey;
  }

.square-color-picker {
  width: 20px; /* Set to desired square size */
  height: 20px; /* Same as width to make it square */
  border: 1px ridge #484855;
  padding: 0; /* Removes padding */
  cursor: pointer; /* Optional: changes cursor on hover */
}

/* Specific styles for WebKit browsers to remove default styling */
.square-color-picker::-webkit-color-swatch-wrapper {
  padding: 0;
}

.square-color-picker::-webkit-color-swatch {
  border: none;
}

.logo-nav {
  height: 40px;
  width: auto;
}

.social-icon {
  height: 40px;
  width: auto;
  padding: 10px;
}

.section-btn {
  display: block;
  background: none;
  border: none;
  width: 100%;
  text-align: left;
  padding: 10px;
  cursor: pointer;
}

/* Sidebar styles */
/* Sidebar styles */
/* Sidebar styles */
.sidebar {
  width: 250px;
  min-height: auto;
  height: auto;
  position: relative;
  top: 0;
  left: 0;
  bottom: 0;
  background: rgb(25,25,34);
  background: radial-gradient(circle, rgba(25,25,34,1) 0%, rgba(21,21,28,1) 100%);
  padding: 20px;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
}

.sub-nav.side-bar-body {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-in-out;
}

.sub-nav.side-bar-body.open {
  max-height: 200px; /* Adjust as necessary based on your content */
}

.sub-nav.side-bar-body.transitioning {
  max-height: 0;
}

.section-btn {
  display: block;
  background: none;
  border: none;
  width: 100%;
  text-align: left;
  padding: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.sub-nav {
  padding-left: 20px;
  margin-bottom: 15px; 
}

.sub-nav-link {
  display: block;
  padding: 5px 0;
  /* background: rgb(25,25,34);
  background: radial-gradient(circle, rgba(25,25,34,1) 0%, rgba(21,21,28,1) 100%); */
  
}

.badge-custom {
  font-family: League+Spartan;
  font-weight: 400;
  font-size: 12px;
  background-color: #7272d6;
  color: white; /* White text color */
  padding: 5px 5px 2px 5px; /* Adjust padding if needed */
}


/* New styles for responsive behavior */
.hamburger-menu {
  display: none;
  cursor: pointer;
  padding: 10px;
}

.hamburger-icon {
  width: 25px;
  height: 3px;
  background-color: white;
  margin: 4px 0;
}

/* Media query for screen size 600px or less */
@media (max-width: 600px) {
  .sidebar {
    position: absolute;
    top: 0;
    left: -250px;
    height: 100%;
    width: 250px;
    z-index: 1000;
    transition: left 0.3s ease;
  }

  .sidebar.open {
    left: 0;
  }

  .hamburger-menu {
    display: block;
  }
}



/* ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■*/

/* |||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||| */
/* |||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||| */
/* |||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||| */

